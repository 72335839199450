import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const NotFound = () => {
  const ogImage = 'https://res.cloudinary.com/dtcmf6iqn/image/upload/v1701269136/swa8ktluihucqayhmhse.png'; // Replace with your actual image URL
const ogUrl = 'https://example.com/your-page';
  return (
    <>
     <Helmet>
    <title>Lantana Marketing Limited - Real Estate Services</title>
    <meta name="description" content="Lantana Marketing Limited - Your best place to sell and rent your property."/>
    <meta property="og:title" content="Lantana Marketing Limited" />
    <meta property="og:description" content="Lantana Marketing Limited - Your best place to sell and rent your property and vehicles" />
    <meta property="og:image" content={ogImage} />
    <meta property="og:url" content={ogUrl} />
  </Helmet>
    <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container text-center">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <i className="bi bi-exclamation-triangle display-1 text-primary"></i>
            <h1 className="display-1">404</h1>
            <h1 className="mb-4">Page Not Found</h1>
            <p className="mb-4">
              We’re sorry, the page you have looked for does not exist on our website! Maybe go to our home page or try to use a search?
            </p>
            <Link to="/" className="btn btn-primary py-3 px-5">
              Go Back To Home
            </Link>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default NotFound;
