import React from 'react'
import UserDashboard from '../Pages/UserDashboard';

const PostAd = () => {
  return (
    <UserDashboard />
  )
}

export default PostAd
