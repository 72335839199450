import React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import PropertySearch from '../components/PropertySearch';
import PropertyTypes from '../components/PropertyTypes';
import AboutSection from '../components/AboutSection';
import PropertyListing from '../components/PropertyListing';
import ContactSection from '../components/ContactSection';
import ClientTestimonials from '../components/ClientTestimonials';
import VehicleListing from '../components/VehicleListing';
import LatestProperties from '../components/LatestProperties';
import LatestVehicles from '../components/LatestVehicles';



const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const ogImage = 'https://res.cloudinary.com/dtcmf6iqn/image/upload/v1701269136/swa8ktluihucqayhmhse.png'; // Replace with your actual image URL
  const ogUrl = 'https://example.com/your-page';

  return (
    <>
      <Helmet>
      <title>Lantana Marketing Pvt Limited - Real Estate Services</title>
      <meta name="description" content="Lantana Marketing Limited - Your best place to sell and rent your property."/>
        <meta property="og:title" content="Lantana Marketing Pvt Limited" />
        <meta property="og:description" content="Lantana Marketing Pvt Limited - Your best place to sell and rent your property and vehicles" />
        <meta property="og:image" content={ogImage} />
        <meta property="og:url" content={ogUrl} />
      </Helmet>

      <Header />
      <PropertySearch />
      <PropertyTypes />
      <AboutSection />
      <PropertyListing />
      <LatestProperties />
      <VehicleListing />
      <LatestVehicles />
      <ContactSection />
     {/* <PropertyAgents /> */}
      <ClientTestimonials />
    </>
  );
};


export default Home
